import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private scripts: { [key: string]: boolean } = {};

  constructor() {}

  loadScript(name: string, src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.scripts[name]) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = src;
      script.type = 'text/javascript';
      script.onload = () => {
        this.scripts[name] = true;
        resolve();
      };
      script.onerror = () => {
        reject();
      };

      document.head.appendChild(script);
    });
  }

  unloadScript(name: string): void {
    const scripts = document.head.getElementsByTagName('script');
    for (let i = scripts.length - 1; i >= 0; i--) {
      const script = scripts[i];
      if (script.src.includes(name)) {
        document.head.removeChild(script);
        delete this.scripts[name];
      }
    }
  }
}
